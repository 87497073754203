@import 'npm:modern-css-reset/dist/reset.min.css';

/* Variables */

$text-color: #222;
$bg-color: #f2f2f2;
$but-color: teal;

/* General styling */

body {
    font-size: 16px;
    margin-top: 2vh;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    color: $text-color;
    background-color: $bg-color;
    background-image: radial-gradient($but-color 1px, $bg-color 1px);
    background-size: 50px 50px;
}

/* More styles */

h1 {
    font-size: 40px;
    margin: 1rem;
}

ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}

ul li {
    background-color: lighten($bg-color, 10%);
    max-height: 500px;
    margin-bottom: 1.5em;
    border: 1px solid grey;
    box-shadow: 5px 5px 5px $text-color;
    padding: 2em;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
}

footer {
    position: relative;
}

button {
    border-radius: 10px;
    color: $bg-color;
    background-color: $but-color;
    border: 3px outset $but-color;
    transition: background-color .5s, border-color .5s;

    &:hover {
        background-color: darken($but-color, 10%);
        border-color: darken($but-color, 10%); 
    }
}

.delete-button, #delete-storage {
    background-color: red;
    border-color: red;

    &:hover {
        background-color: darken(red, 20%);
        border-color: darken(red, 20%); 
    }
}