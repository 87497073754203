*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  min-height: 100vh;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

body {
  text-align: center;
  color: #222;
  background-color: #f2f2f2;
  background-image: radial-gradient(teal 1px, #f2f2f2 1px);
  background-size: 50px 50px;
  margin-top: 2vh;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
}

h1 {
  margin: 1rem;
  font-size: 40px;
}

ul {
  flex-direction: column;
  align-items: center;
  display: flex;
}

ul li {
  background-color: #fff;
  border: 1px solid gray;
  flex-flow: column wrap;
  max-height: 500px;
  margin-bottom: 1.5em;
  padding: 2em;
  display: flex;
  box-shadow: 5px 5px 5px #222;
}

footer {
  position: relative;
}

button {
  color: #f2f2f2;
  background-color: teal;
  border: 3px outset teal;
  border-radius: 10px;
  transition: background-color .5s, border-color .5s;
}

button:hover {
  background-color: #004d4d;
  border-color: #004d4d;
}

.delete-button, #delete-storage {
  background-color: red;
  border-color: red;
}

.delete-button:hover, #delete-storage:hover {
  background-color: #900;
  border-color: #900;
}

/*# sourceMappingURL=index.a26d073e.css.map */
